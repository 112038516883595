import React from "react"
import {
    AcademicCapIcon,
    CheckCircleIcon,
    HandRaisedIcon,
    RocketLaunchIcon,
    SparklesIcon,
    SunIcon,
    UserGroupIcon,
} from '@heroicons/react/20/solid'
import CustomLottie from "../../components/LottiePlayer";
import BizLottie from '../../images/bis.json'

const BusinessDesc = (props) => {
    const benefits = [
        'Traditional stop-loss',
        'Multiple contract options',
        'Specific advance included at no charge on all policies',
        '    Reference-based pricing options, aggregate accommodation dollar-for-dollar aggregating specific, and TLO options available',
        'Level funded stop-loss',
        '   Individual application review and analysis',
        'Underwriting consulting',
        'Captive underwriting services'
    ]
    return (
        <>
         
            {/* CTA section */}
            <div className="relative bg-slate-900 isolate -z-10 ">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                        {/* <img
                            className="h-96 w-full flex-none  object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                            src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                            alt=""
                        /> */}
                        {/* <CustomLottie animationData={BizLottie} /> */}
                        <div className="w-full flex-auto">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Description of Business</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                BRM is a comprehensive Managing General Underwriter (MGU) that offers an array of medical stop loss products and services to support the vast growing needs of the ESL marketplace. Our commitment to offering best in class service and product solutions to the Broker Community, Third Party Administrators, and direct consumer relationships will be the fabric of our corporate DNA. The following products and services are offered:
                            </p>
                            <ul
                                role="list"
                                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                            >
                                {benefits.map((benefit) => (
                                    <li key={benefit} className="flex gap-x-3">
                                        <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                            {/* <div className="mt-10 flex">
                                <a href="#" className="text-sm font-semibold leading-6 text-blue-400">
                                    See our job postings <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                    />
                </div>
            </div>
        </>
    )
};

export default BusinessDesc;
