import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import SpecificImage from '../images/images/spetic-img01.jpg'



export default function HeroAffiliate() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative sm:pt-2 pt-14 lg:w-full lg:max-w-2xl">
     

            <div  data-aos="fade-right" className="relative px-6 py-12 sm:py-4 lg:px-8 lg:py-20 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
        
                <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                 Affiliation
                </h1>
             

              </div>
            </div>
          </div>
        </div>
   
      </div>

    </div>
  )
}
