"use client"

import Lottie from "lottie-react";
import animationData from "../images/Insurance.json";
export default function CustomLottie() {
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <Lottie
        animationData={animationData}
        className="flex justify-center items-center"
        loop={true}
      />
    </div>
  );
}