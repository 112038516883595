export default function Stats02() {
  return (
    <>
      <section className="-translate-y-1/2">

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto mb-6 ">

            <div className="bg-blue-600 py-3 sm:py-6 shadow-xl">
              <ul className="flex">
                <li className="relative w-1/3 px-1 text-center  after:hidden sm:after:block last:after:hidden">
                  <div className="text-4xl md:text-5xl font-playfair-display font-bold text-white mb-2">30+</div>
                  {/* <div className="text-xs sm:text-sm md:text-base text-blue-200 font-medium">Years Experience</div> */}
                </li>
                <li className="relative w-1/4    after:hidden sm:after:block last:after:hidden">
                  <div className="text-4xl md:text-5xl font-playfair-display font-bold text-white mb-2">Years</div>
                  {/* <div className="text-xs sm:text-sm md:text-base text-blue-200 font-medium">Capital Raised</div> */}
                </li>
                <li className="relative w-1/4 pr-1  after:absolute ">
                  <div className="text-4xl md:text-5xl font-playfair-display font-bold text-white mb-2">Experience</div>
                  {/* <div className="text-xs sm:text-sm md:text-base text-blue-200 font-medium">Team Members</div> */}
                </li>
              </ul>
            </div>
   
          </div>
   
        </div>
        {/* <div className=" ">
              <h1 className="h1">Our Story</h1>
              <p>Our expertise in sales, carrier relations, and underwriting of stop-loss, provides our clients with an easier way to do business, tools for success, and a partnership that provides timely, responsive customer service.

                We understand that every client's needs are different, and we offer a full range of flexible services, including underwriting, policy binding, contract issuance, premium collection and claims payment.</p>
            </div> */}
      </section>
    </>
  )
}