import { RouterProvider } from "react-router-dom"
import { useEffect } from "react"
import router from "./router"
import Header from "./layout/nav/NavHorizontal"
import Footer from "./layout/nav/Footer"
import StackedHeader from "./layout/nav/StackedMenuHeader"


import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='App'>
      <StackedHeader />
      <RouterProvider router={router} />
      <Footer />
    </div>
  )
}

export default App
