import PricingTables from '../components/pricing-tables';
import ClaimsTables from '../components/claims-tables';


export default function HeroClaims() {  
  return (
    <section className="relative">

      {/* Dark background */}
      <div className="absolute inset-0 bg-slate-100 pointer-events-none -z-10 h-1/3 lg:h-[48rem] [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]" aria-hidden="true"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-2">

          {/* Section header */}
       

          <ClaimsTables />

        </div>
      </div>

    </section>
  )
}