// import Accordion from '@/components/utils/accordion'
import Accordion from "../components/utils/accordion"

export default function Faqs(teamMember) {
  let data = teamMember?.teamMember[0] 
  console.log(data.fun_facts)
  return (
    <section className="bg-slate-50"data-aos-delay="200">

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-slate-50">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-playfair-display text-slate-800">Fun Facts about {data.firstName}</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto divide-y divide-slate-200">
            {data?.fun_facts.map((fact) => 
            <Accordion title={fact.question} >
            {fact.answer}
            </Accordion>
)}
            
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div>
      </div>
    </section>
  )
}