import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
  } from '@heroicons/react/20/solid'
  import React from "react"
  import {
  
      CheckCircleIcon,
  
  } from '@heroicons/react/20/solid'
  import CustomLottie from '../components/LottiePlayer'
  import ServicesJson from '../images/Insurance.json'
  
  const features = [
    {
      name: ' A La Carte Underwriting Services',
      description: '',
      icon: CloudArrowUpIcon,
    },
    {
      name: ' Claims',
      description: '',
      icon: LockClosedIcon,
    },
    {
      name: ' Underwriting',
      description: '',
      icon: ArrowPathIcon,
    },
    {
      name: ' Level Funded',
      description: '',
      icon: FingerPrintIcon,
    },
    {
      name: ' Captive Evaluation',
      description: '',
      icon: Cog6ToothIcon,
    },
    {
      name: 'Database backups.',
      description: ' ',
      icon: ServerIcon,
    },
  ]
  
//   export default function Example() {
//     return (
//       <div className="bg-white py-12 sm:py-12">
//         <div className="mx-auto max-w-7xl px-6 lg:px-8">
//           <div className="mx-auto max-w-3xl sm:text-center">
//             <h2 className="text-base font-semibold leading-7 text-blue-600">BRM</h2>
//             <p className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Services</p>
//             <p className="mt-6 text-lg leading-8 text-gray-600">
//             BRM is a Medical Stop Loss Managing General Agency that was founded by brokers/consultants for brokers/consultants. We understand the struggles that consultants and brokers face each day and provide underwriting solutions to help them gain and retain more clients.
//             </p>
//           </div>
//         </div>
//         <div className="relative overflow-hidden pt-16">
//           <div className="mx-auto max-w-2xl px-6 lg:px-8">
//             <img
//               src="https://cdn.pixabay.com/photo/2018/03/03/20/02/laptop-3196481_1280.jpg"
//               alt="App screenshot"
//               className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
//               width={2432}
//               height={1442}
//             />
//             <div className="relative" aria-hidden="true">
//               <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
//             </div>
//           </div>
//         </div>
//         <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
//           <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-center leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
//             {features.map((feature) => (
//               <div key={feature.name} className="relative px-2">
//                 <dt className="h4 font-playfair-display mb-2">
//                   <feature.icon className="absolute left-1 top-1 h-5 w-5 text-blue-600" aria-hidden="true" />
//                   {feature.name}
//                 </dt>{' '}
//                 <dd className="inline">{feature.description}</dd>
//               </div>
//             ))}
//           </dl>
//         </div>
//       </div>
//     )
//   }
  



const BusinessDesc = (props) => {
    const benefits = [
        'A La Carte Underwriting Services',
        'Claims',
        'Underwriting',
        'Level Funded',
        'Level funded stop-loss',
        'Captive Evaluation',

    ]
    return (
        <>
         
            {/* CTA section */}
            <div className="relative bg-slate-800 isolate -z-10 mt-32 sm:mt-40">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                        {/* <img
                            className="h-96 w-full flex-none  object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                            src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
                            alt=""
                        /> */}
                        <CustomLottie animationData={ServicesJson} />
                        <div className="w-full flex-auto">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">Services</h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                            BRM is a Medical Stop Loss Managing General Agency that was founded by brokers/consultants for brokers/consultants. We understand the struggles that consultants and brokers face each day and provide underwriting solutions to help them gain and retain more clients.
                            </p>
                            <ul
                                role="list"
                                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
                            >
                                {benefits.map((benefit) => (
                                    <li key={benefit} className="flex gap-x-3">
                                        <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                            {/* <div className="mt-10 flex">
                                <a href="#" className="text-sm font-semibold leading-6 text-blue-400">
                                    See our job postings <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                    />
                </div>
            </div>
        </>
    )
};

export default BusinessDesc;
