import React, { useState } from "react"
import { Document, Page, PDFViewer } from '@react-pdf/renderer';
import { useLocation } from "react-router-dom";

const PDFView = () => {
  const params = useLocation()

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    //     <PDFViewer className="main"> 
    //     <Document 
    //       file={pdfPath} 
    //       onLoadSuccess={onDocumentLoadSuccess} 
    //       > 
    //       <Page pageNumber={pageNumber} width={800} /> 
    //     </Document> 
    //    </PDFViewer> ]
    <>
      <iframe src={params?.state?.fileUrl} width="100%" height="800" />
    </>
  );
};
export default PDFView;

