import React from "react"
import TeamMembers from "../../sections/team-members";
const TeamPage = (props) => {
  return (
    <div>
      <TeamMembers />
    </div>
  )
};

export default TeamPage;
