import { createBrowserRouter } from "react-router-dom";
//import ErrorPage from "./pages/ErrorPage";
import MainLayout from "./layout/Main";
import ProductPage from "./pages/products/Products";
import HomePage from "./pages/home/Home";
import AboutPage from "./pages/about/About";
import ContactPage from "./pages/contact/Contact";
import TeamPage from "./pages/team/Team";
import ClaimsPage from "./pages/claims/Claims";
import ResourcesPage from "./pages/about/Resources";
import FormsPage from "./pages/about/Forms";
import AffilationPage from "./pages/about/Affiliation";
import SpecificStopLossPage from "./pages/products/SpecificStopLoss";
import MonthlyPage from "./pages/products/Monthly";
import AggregateStopLossPage from "./pages/products/AggregateStopLoss";
import ALaCartePage from "./pages/services/ALaCarte";
import CapitalPage from "./pages/services/Capital";
import ClaimsPage2 from "./pages/services/Claims";
import UnderwritingPage from "./pages/services/Underwriting";
import EboniScottPage from "./pages/team/EboniScott";
import TestimonialsPage from "./pages/about/Testimonials";
import ViewPdf from "./sections/resources-sections/ViewForm";
import PDFView from "./components/PDFViewer";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/about/team/:id",
    element: <EboniScottPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/testimonials",
    element: <TestimonialsPage />
  },
  {
    path: "/about/resources",
    element: <ResourcesPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/about/forms",
    element: <FormsPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/forms/:id",
    element: <PDFView />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/about/affiliation",
    element: <AffilationPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/about/team",
    element: <TeamPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/product",
    element: <ProductPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/product/specific-stop-loss",
    element: <SpecificStopLossPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/product/aggregate-stop-loss",
    element: <AggregateStopLossPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/product/monthly-accomadation",
    element: <MonthlyPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <ProductPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/services/a-la-carte",
    element: <ALaCartePage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/services/claims",
    element: <ClaimsPage2 />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/services/underwriting",
    element: <UnderwritingPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/services/capital-funding",
    element: <CapitalPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/claims",
    element: <ClaimsPage />,
    //errorElement: <ErrorPage />,
  },
  {
    path: "/request-a-quote",
    element: <ContactPage />,
    //errorElement: <ErrorPage />,
  },

]);

export default router;