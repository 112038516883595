import React from "react"

import HeroAgg from "../../sections/hero-aggregate";
import Clients from "../../sections/clients";
import Customers from "../../sections/customers";
import Testimonials from "../../sections/testimonials";
import CtaDark from "../../sections/cta-dark";

const AggregateStopLossPage = (props) => {

    return (
          <>
            <HeroAgg />
   
            <CtaDark />
          </>

      
  )
};

export default AggregateStopLossPage;
