import {
  
    CheckCircleIcon,

} from '@heroicons/react/20/solid'
const features1 = [
    {
      name: ' Minimum group size',
      description: '20 lives (subject to state requirements)',

    },
    {
      name: 'Minimum specific deductible',
      description: '$15,000.00',
 
    },


  ]
  const features2 = [

    {
      name: 'Specific advancement/immediate reimbursement included at no charge on all policies.',
      description: '',
   
    },
 


  ]
  const features3 = [

    {
      name: 'Standard contract options',
      description: ' 12/12, 12/15, 12/18, 15/12, 18/12, 24/12',

    },
    {
      name: 'Aggregate Stop Loss Coverage',
      description: '',

    },

  ]


export default function Services3() {
    return (
      <section className="bg-slate-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20">
  
            {/* Section header */}
            <div className="max-w-3xl mx-auto lg:mx-0 text-center lg:text-left pb-12 md:pb-20">
              <h2 className="h2 font-playfair-display text-slate-800">Specific Stop-Loss Coverage</h2>
            </div>
  
            {/* Items */}
            <div className="relative max-w-sm mx-auto grid gap-8 lg:gap-16 md:grid-cols-3 lg:gap-y-20 items-start md:max-w-none">
  
              {/* 1st item */}
              <div className="relative" data-aos="fade-up">
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-a">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-29.2%" y="-20.8%" width="158.3%" height="158.3%" filterUnits="objectBoundingBox" id="fbp3-c">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-f">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-h">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <rect id="fbp3-b" x="21" y="21.998" width="10" height="10" rx="2" />
                    <rect id="fbp3-g" x="33" y="33.998" width="10" height="10" rx="2" />
                    <rect id="fbp3-i" x="21" y="33.998" width="10" height="10" rx="2" />
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="fbp3-e">
                      <stop stopColor="#FFF" offset="0%" />
                      <stop stopColor="#DBEAFE" offset="100%" />
                    </linearGradient>
                    <path d="m43.428 25.616-4.047-4.046a1.957 1.957 0 0 0-2.762 0l-4.047 4.046c-.76.765-.76 2 0 2.764l4.047 4.047c.764.76 1.998.76 2.762 0l4.047-4.046c.761-.764.761-2 0-2.765Z" id="fbp3-d" />
                  </defs>
                  <rect className="fill-current text-blue-600" width="64" height="64" rx="32" />
                  <use fill="#000" filter="url(#fbp3-a)" xlinkHref="#fbp3-b" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-b" />
                  <use fill="#000" filter="url(#fbp3-c)" xlinkHref="#fbp3-d" />
                  <use fill="url(#fbp3-e)" xlinkHref="#fbp3-d" />
                  <use fill="#000" filter="url(#fbp3-f)" xlinkHref="#fbp3-g" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-g" />
                  <use fill="#000" filter="url(#fbp3-h)" xlinkHref="#fbp3-i" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-i" />
                </svg> */}



                <ul
                                role="list"
                                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-slate-800 sm:grid-cols-1"
                            >
                                {features1.map((benefit) => (
                                    <li class='text-2xl' key={benefit.name} className="flex gap-x-1">
                                        <CheckCircleIcon className="h-7 w-7 flex-none" aria-hidden="true" />
                                        {benefit.name} = {benefit.description}
                                    </li>
                                ))}
                            </ul>
              </div>
  
              {/* 2nd item */}
              <div className="relative" data-aos="fade-up" data-aos-delay="100">
              {/* <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <defs>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-a">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-29.2%" y="-20.8%" width="158.3%" height="158.3%" filterUnits="objectBoundingBox" id="fbp3-c">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-f">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <filter x="-35%" y="-25%" width="170%" height="170%" filterUnits="objectBoundingBox" id="fbp3-h">
                      <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                      <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                      <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                      <feColorMatrix values="0 0 0 0 0.062745098 0 0 0 0 0.11372549 0 0 0 0 0.176470588 0 0 0 0.12 0" in="shadowBlurOuter1" />
                    </filter>
                    <rect id="fbp3-b" x="21" y="21.998" width="10" height="10" rx="2" />
                    <rect id="fbp3-g" x="33" y="33.998" width="10" height="10" rx="2" />
                    <rect id="fbp3-i" x="21" y="33.998" width="10" height="10" rx="2" />
                    <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="fbp3-e">
                      <stop stopColor="#FFF" offset="0%" />
                      <stop stopColor="#DBEAFE" offset="100%" />
                    </linearGradient>
                    <path d="m43.428 25.616-4.047-4.046a1.957 1.957 0 0 0-2.762 0l-4.047 4.046c-.76.765-.76 2 0 2.764l4.047 4.047c.764.76 1.998.76 2.762 0l4.047-4.046c.761-.764.761-2 0-2.765Z" id="fbp3-d" />
                  </defs>
                  <rect className="fill-current text-blue-600" width="64" height="64" rx="32" />
                  <use fill="#000" filter="url(#fbp3-a)" xlinkHref="#fbp3-b" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-b" />
                  <use fill="#000" filter="url(#fbp3-c)" xlinkHref="#fbp3-d" />
                  <use fill="url(#fbp3-e)" xlinkHref="#fbp3-d" />
                  <use fill="#000" filter="url(#fbp3-f)" xlinkHref="#fbp3-g" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-g" />
                  <use fill="#000" filter="url(#fbp3-h)" xlinkHref="#fbp3-i" />
                  <use fillOpacity=".64" fill="#BFDBFE" xlinkHref="#fbp3-i" />
                </svg> */}
                <ul
                                role="list"
                                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-slate-800 sm:grid-cols-1"
                            >
                                {features2.map((benefit) => (
                                    <li class='text-2xl' key={benefit.name} className="flex gap-x-1">
                                        <CheckCircleIcon className="h-7 w-7 flex-none" aria-hidden="true" />
                                        {benefit.name}  {benefit.description}
                                    </li>
                                ))}
                            </ul>
              </div>
  
              {/* 3rd item */}
              <div className="relative" data-aos="fade-up" data-aos-delay="200">

                <ul
                                role="list"
                                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-slate-800 sm:grid-cols-1"
                            >
                                {features3.map((benefit) => (
                                    <li class='text-2xl' key={benefit.name} className="flex gap-x-1">
                                        <CheckCircleIcon className="h-7 w-7 flex-none text-green" aria-hidden="true" />
                                        {benefit.name} 
                                    </li>
                                ))}
                            </ul>
              </div>
  
            </div>
  
          </div>
        </div>
      </section>
    )
  }