import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import SpecificImage from '../images/images/spetic-img01.jpg'
import CtaDark from './cta-dark'



export default function HeroCarte() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative  pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div  data-aos="fade-right" className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-20 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="hidden sm:mb-10 sm:flex">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    {/* Anim aute id magna aliqua ad ad non deserunt sunt.{' '} */}
                    <a href="#" className="whitespace-nowrap font-semibold text-blue-600">
                      <span className="absolute inset-0" aria-hidden="true" />
                      Our Services <span aria-hidden="true"></span>
                    </a>
                  </div>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                A La Carte
Underwriting
                </h1>
                {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                An Array of Medical
Stop Loss Products
                </p> */}
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/contact"
                    className=" bg-blue-600 px-5 py-5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Get a Quote
                  </a>
                  {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-left" className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={SpecificImage}
            alt=""
          />
        </div>
      </div>
      <section className="bg-slate-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-playfair-display text-slate-800">  An Array of Medical
Stop Loss Products</h2>
          </div> */}
          {/* Testimonials container */}
          <div className="max-w-sm mx-auto sm:max-w-none grid gap-12  mb-12 md:mb-16" data-aos-id-testimonials>
     
In addition to traditional MGU stop-loss services, BRM also offers expert ala carte underwriting and risk management consulting to clients seeking an independent actuarial evaluation. The BRM Underwriting team will provide clients with a transparent analysis on the adequacy of existing or proposed risks and rates. If you need validate or obtain an alternate underwriting opinion, let the BRM team provide you with a second look or provide you with a professional risk proposal.

          </div>
          {/* See All Customers */}
  
        </div>
      </div>
    </section>
    <CtaDark />
    </div>
  )
}
