
import { Link } from 'react-router-dom'
import PLanImage from '../images/images/service-sec-img02.jpg'
import ThirdImage from '../images/images/specific-img03.jpg'
import BrokerImage from '../images/images/la-carte-img02.jpg'


// export const metadata = {
//   title: 'Blog - Tidy',
//   description: 'Page description',
// }


export default function HeroRoles() {

  // Sort posts by date



  return (
    <>


      {/* Featured article */}
      <section>

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-8 md:py-16">

            <article className="max-w-sm mx-auto space-y-5 md:max-w-none md:flex md:items-center md:space-y-0 md:space-x-8 lg:space-x-16">
              {/* Image */}
           
                <Link className="relative block group overflow-hidden md:w-1/2" href={`https://res.cloudinary.com/codeshock/image/upload/v1701812232/team-group_yopwbp.jpg`} data-aos="fade-down">
                  <img className="w-full aspect-[16/9] md:aspect-[27/17] object-cover group-hover:scale-105 transition duration-700 ease-out" src={PLanImage} width={540} height={340} priority alt='hello' />
                  <div className="absolute top-6 right-6">
                    {/* <svg className="w-8 h-8" viewBox="0 0 32 32">
                      <circle className="fill-slate-900" fillOpacity=".48" cx="16" cy="16" r="16" />
                      <path className="fill-yellow-500" d="M21.954 14.29A.5.5 0 0 0 21.5 14h-5.36l.845-3.38a.5.5 0 0 0-.864-.446l-6 7A.5.5 0 0 0 10.5 18h5.359l-.844 3.38a.5.5 0 0 0 .864.445l6-7a.5.5 0 0 0 .075-.534Z" />
                    </svg> */}
                  </div>
                </Link>
              
              {/* Content */}
              <div className="md:w-1/2" data-aos="fade-up">
                <header>
                  <h2 className="h4 md:text-4xl lg:text-5xl font-playfair-display mb-3">
                    <Link className="text-slate-800 hover:underline hover:decoration-blue-100" href={`https://res.cloudinary.com/codeshock/image/upload/v1701812232/team-group_yopwbp.jpg`}>Plan Administrator</Link>
                  </h2>
                </header>
                <p className="text-lg text-slate-500 grow">In today’s environment, employers want to take care of their employees while maintaining fiscal integrity for the viable growth of the company. The last thing an employer needs to be concerned with are health care insurance complexities. BRM has a different approach focused on simplifying the benefits process. Ask us how we can assist!</p>
       
              </div>
            </article>

          </div>

        </div>
        
      </section>


       
      {/* Featured article */}
      <section>

<div className="max-w-6xl mx-auto px-4 sm:px-6">
  <div className="py-8 md:py-16">

    <article className="max-w-sm mx-auto space-y-5 md:max-w-none md:flex md:items-center md:space-y-0 md:space-x-8 lg:space-x-16">
      {/* Image */}
   

      
      {/* Content */}
      <div className="md:w-1/2" data-aos="fade-up">
        <header>
          <h2 className="h4 md:text-4xl lg:text-5xl font-playfair-display mb-3">
            <Link className="text-slate-800 hover:underline hover:decoration-blue-100" href={'/'}>Third Party Administrators</Link>
          </h2>
        </header>
        <p className="text-lg text-slate-500 grow">In today’s environment, employers want to take care of their employees while maintaining fiscal integrity for the viable growth of the company. The last thing an employer needs to be concerned with are health care insurance complexities. BRM has a different approach focused on simplifying the benefits process. Ask us how we can assist!</p>

      </div>
      <Link className="relative block group overflow-hidden md:w-1/2" href={'/'} data-aos="fade-down">
          <img className="w-full aspect-[16/9] md:aspect-[27/17] object-cover group-hover:scale-105 transition duration-700 ease-out" src={ThirdImage} width={540} height={340} priority alt='hello' />
          <div className="absolute top-6 right-6">
            {/* <svg className="w-8 h-8" viewBox="0 0 32 32">
              <circle className="fill-slate-900" fillOpacity=".48" cx="16" cy="16" r="16" />
              <path className="fill-yellow-500" d="M21.954 14.29A.5.5 0 0 0 21.5 14h-5.36l.845-3.38a.5.5 0 0 0-.864-.446l-6 7A.5.5 0 0 0 10.5 18h5.359l-.844 3.38a.5.5 0 0 0 .864.445l6-7a.5.5 0 0 0 .075-.534Z" />
            </svg> */}
          </div>
        </Link>
    </article>

  </div>

</div>
</section>

   
      {/* Featured article */}
      <section>

        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-8 md:py-16">

            <article className="max-w-sm mx-auto space-y-5 md:max-w-none md:flex md:items-center md:space-y-0 md:space-x-8 lg:space-x-16">
              {/* Image */}
           
                <Link className="relative block group overflow-hidden md:w-1/2" href={`https://res.cloudinary.com/codeshock/image/upload/v1701812232/team-group_yopwbp.jpg`} data-aos="fade-down">
                  <img className="w-full aspect-[16/9] md:aspect-[27/17] object-cover group-hover:scale-105 transition duration-700 ease-out" src={BrokerImage} width={540} height={340} priority alt='hello' />
                  <div className="absolute top-6 right-6">
                    {/* <svg className="w-8 h-8" viewBox="0 0 32 32">
                      <circle className="fill-slate-900" fillOpacity=".48" cx="16" cy="16" r="16" />
                      <path className="fill-yellow-500" d="M21.954 14.29A.5.5 0 0 0 21.5 14h-5.36l.845-3.38a.5.5 0 0 0-.864-.446l-6 7A.5.5 0 0 0 10.5 18h5.359l-.844 3.38a.5.5 0 0 0 .864.445l6-7a.5.5 0 0 0 .075-.534Z" />
                    </svg> */}
                  </div>
                </Link>
              
              {/* Content */}
              <div className="md:w-1/2" data-aos="fade-up">
                <header>
                  <h2 className="h4 md:text-4xl lg:text-5xl font-playfair-display mb-3">
                    <Link className="text-slate-800 hover:underline hover:decoration-blue-100" href={`https://res.cloudinary.com/codeshock/image/upload/v1701812232/team-group_yopwbp.jpg`}>Brokers</Link>
                  </h2>
                </header>
                <p className="text-lg text-slate-500 grow">We speak your language, and we offer cost-effective, practical options to best service you. We’ll work with you to design solutions for potential and existing clients.</p>
       
              </div>
            </article>

          </div>

        </div>
      </section>
  
  
    </>
  )
}
