import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import TeamMemberHero from "../../sections/team-sections/team-member-hero";
import Faqs from "../../sections/faqs";
import TeamMemberAbout from "../../sections/team-sections/team-member-about";
import { teamData } from "../../sections/team-sections/team-data";

const TeamMemberPage = (props) => {
  const location = useLocation()
  const [teamMember, setTeamMember] = useState(null)
  useEffect(() => {
    let newArray = teamData.filter(function (item) {
      console.log(item.lastName)

      return item.lastName.includes(location.state.lastName)
    });
     console.log('new', newArray)
    setTeamMember(newArray)
    console.log('t', teamMember)
  }, [])
  return (
    <div>
      <TeamMemberHero teamMember={teamMember} />
     {teamMember && teamMember[0]?.details ? 
      <TeamMemberAbout teamMember={teamMember} />
      : null}
         {teamMember && teamMember[0]?.fun_facts ? 
      <Faqs teamMember={teamMember} /> : null}
    </div>
  )
};

export default TeamMemberPage;
