import React from "react"
import HeroWol from "../../sections/hero-stop";
import Clients from "../../sections/clients";
import Customers from "../../sections/customers";
import Testimonials from "../../sections/testimonials";
import CtaDark from "../../sections/cta-dark";

const SpecificStopLossPage = (props) => {

    return (
          <>
            <HeroWol />
            {/* <Clients /> */}
            {/* <Customers /> */}
            <Testimonials />
            <CtaDark />
          </>

      
  )
};

export default SpecificStopLossPage;
