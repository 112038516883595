import { Link } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { FadeIn, FadeInStagger } from '../components/FadeIn'
import RomanImage from '../images/images/Roman-Head-Shot.jpg'
// import RomanImage from '../images/images/roman-back.jpg'
import GradyImage from '../images/images/grady-img.jpg'
import CarrieImage from '../images/images/carrie-img01.jpg'
import AnthonyImage from '../images/images/Anthony-img01.jpg'
import SilImage from '../images/images/sil.jpg'
import AudreyImage from '../images/images/Audrey-Weiser-front.jpg'
import SieannaImage from '../images/images/Sieanna-Rahatt-front.jpg'
import BevImage from '../images/images/Beverly-Beebe-front.jpg'
import BrandyImage from '../images/images/Brandy-Schaffer-front.jpg'
import EboniImage from '../images/images/Eboni-Scott-front.jpg'
import ShaynaImage from '../images/images/Shayna-Actis-front.jpg'
// import CarliImage from '../images/images/PlayCSavage.JPEG'
import MollyImage from '../images/images/Molly-Lyons-front.jpg'
import MargieImage from '../images/images/MH.jpg'
import GinaImage from '../images/images/GMD.png'
import JImage from '../images/images/JW.jpg'

import { teamMemberState } from '../recoil/team'

export default function TeamMembers() {
  return (
    <section>
      <div className='max-w-6xl mx-auto px-4 sm:px-6 pb-8'>
        <Team />
      </div>
    </section>
  )
}

const team = [
  // {
  //   title: 'Leadership',
  //   people: [
  //     {
  //       name: 'Leslie Alexander',
  //       role: 'Co-Founder / CEO',
  //       image: { src: EboniImage },
  //     },
  //     {
  //       name: 'Michael Foster',
  //       role: 'Co-Founder / CTO',
  //       image: { src: EboniImage },
  //     },
  //     {
  //       name: 'Dries Vincent',
  //       role: 'Partner & Business Relations',
  //       image: { src: EboniImage },
  //     },
  //   ],
  // },
  {
    title: 'Team',
    people: [
      {
        name: 'Roman McDonald',
        firstName: 'Roman',
        lastName: 'McDonald',
        role: 'Chief Executive Officer',
        image: { src: RomanImage },
      },
      {
        name: 'Grady Ford',
        firstName: 'Grady',
        lastName: 'Ford',
        role: 'Partner',
        image: { src: GradyImage },
      },
      {
        name: 'Carrie Urbanelli',
        firstName: 'Carrie',
        lastName: 'Urbanelli',
        role: 'Chief Underwriting Officer',
        image: { src: CarrieImage },
      },
      {
        name: 'Anthony Fasciano',
        firstName: 'Anthony',
        lastName: 'Fasciano',
        role: 'Controller',
        image: { src: AnthonyImage },
      },
      {
        name: 'Silvana Herbert',
        firstName: 'Silvana',
        lastName: 'Herbert',
        role: 'Director of Underwriting',
        image: { src: SilImage },
      },
      {
        name: 'Audrey Weiser',
        firstName: 'Audrey',
        lastName: 'Weiser',
        role: 'Policy/Underwriting Coordinator',
        image: { src: AudreyImage },
      },
      {
        name: 'Sieanna Rahatt',
        firstName: 'Sieanna',
        lastName: 'Rahatt',
        role: 'Underwriting Coordinator',
        image: { src: SieannaImage },
      },
      {
        name: 'Molly Lyons',
        firstName: 'Molly',
        lastName: 'Lyons',
        role: 'Underwriting Coordinator',
        image: { src: MollyImage },
      },
      {
        name: 'Beverly Beebe',
        firstName: 'Beverly',
        lastName: 'Beebe',
        role: 'Claims Assistant',
        image: { src: BevImage },
      },
      // {
      //   name: 'Brandy Schaffer',
      //   firstName: 'Brandy',
      //   lastName: 'Schaffer',
      //   role: 'Finance Coordinator',
      //   image: { src: BrandyImage },
      // },
      {
        name: 'Eboni Scott',
        firstName: 'Eboni',
        lastName: 'Scott',
        role: 'Administrative Manager',
        image: { src: EboniImage },
      },
      {
        name: 'Shayna Actis',
        firstName: 'Shayna',
        lastName: 'Actis',
        role: 'Director of Claims',
        image: { src: ShaynaImage },
      },
      // {
      //   name: 'Carli Savage',
      //   firstName: 'Carli',
      //   lastName: 'Savage',
      //   role: 'Marketing and Sales',
      //   image: { src: CarliImage },
      // },
      {
        name: 'Margie Heron',
        firstName: 'Margie',
        lastName: 'Heron',
        role: 'Underwriting Coordinator',
        image: { src: MargieImage },
      },
      {
        name: ' Gina Marie D’Auria',
        firstName: 'Gina',
        lastName: 'D’Auria',
        role: 'Junior Underwriter',
        image: { src: GinaImage },
      },
      {
        name: 'Jeremy Walker',
        firstName: 'Jeremy',
        lastName: 'Walker',
        role: 'Finance Coordinator',
        image: { src: JImage },
      },
    ],
  },
]

function Team() {
  const setTeamName = useSetRecoilState(teamMemberState)
  const teamMember = (name) => {
    setTeamName(name)
  }
  const navigate = useNavigate()
  const teamNavigate = (memberName, firstName, lastName) => {
    let dashName = memberName.replace(/\s+/g, '-')
    navigate(`/about/team/${dashName}`, {
      state: { name: memberName, firstName: firstName, lastName: lastName },
    })
  }
  return (
    <>
      <div className='space-y-24'>
        {team.map((group) => (
          <FadeInStagger key={group.title}>
            <div className='grid grid-cols-1 gap-6 pt-12 sm:pt-16 lg:grid-cols-4 xl:gap-8'>
              <FadeIn>
                <h2 className='font-display text-3xl font-semibold text-neutral-950'>Our Family</h2>
              </FadeIn>
              <div className='lg:col-span-3'>
                <ul
                  role='list'
                  className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8'
                >
                  {group.people.map((person) => (
                    <li key={person.name}>
                      <FadeIn>
                        <div className='group relative overflow-hidden  bg-neutral-100'>
                          <img
                            alt=''
                            {...person.image}
                            className='h-96 w-full object-cover  transition duration-500 motion-safe:group-hover:scale-105'
                          />
                          <div className='absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black to-black/0 to-80% p-6'>
                            <h2 className='font-display text-xl text-base/6 font-semibold tracking-wide text-white'>
                              {person.name}
                            </h2>
                            <p className='mt-2 text-md text-white'>{person.role}</p>
                            {/* <Link to={`/about/team/${person.name.replace(/\s+/g, '-')}`}> */}
                            <button
                              className='bg-slate-800 p-2 mt-2 text-white'
                              onClick={() => {
                                teamNavigate(person?.name, person.firstName, person?.lastName)
                              }}
                            >
                              About
                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                      </FadeIn>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </FadeInStagger>
        ))}
      </div>
    </>
  )
}
