import React from "react"
import HeroCarte from "../../sections/hero-a-la-carte";

const ALaCartePage = (props) => {
  return (
    <div>
<HeroCarte />
    </div>
  )
};

export default ALaCartePage;
