import React from "react"
import FormsList from "../../sections/resources-sections/forms-section";
import CtaDark from "../../sections/cta-dark";


const FormsPage = (props) => {
  return (
    <div>
      <FormsList />
<CtaDark />
    </div>
  )
};

export default FormsPage;
