import React from "react"
import HeroAffiliate from "../../sections/hero-affiliate";
import Affiliate from "../../sections/affiliate-section";

const AffilationPage = (props) => {
  return (
    <div>
         <HeroAffiliate />
      <Affiliate />

    </div>
  )
};

export default AffilationPage;
