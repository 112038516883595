
import TestimonialImage01 from '../images/images/MBE-Logo.png'
import TestimonialImage02 from '../images/images/NMSDC-logo.png'
import TestimonialImage03 from '../images/images/NAAIA-logo.png'
import TestimonialImage04 from '../images/images/SIIA_logo.png'
import TestimonialImage05 from '../images/images/gallagher-logo.png'
import TestimonialImage06 from '../images/images/Nabip-logo.png'

export default function Affiliate() {  
  return (
    <section className="bg-slate-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-16">
       
          {/* Testimonials container */}
          <div className="max-w-sm mx-auto sm:max-w-none grid gap-12 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-8 items-start mb-12 md:mb-16" data-aos-id-testimonials>
            {/* 1st Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]">
        
          
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage01} width={260} height={260} alt="Testimonial 01" />
                
                  </div>
            </article>
            {/* 2nd Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]" data-aos-delay="100">
              
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage02} width={260} height={260} alt="Testimonial 02" />
                
                  </div>
                  {/* Acme Inc. logo */}
   
               
            </article>
            {/* 3rd Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]" data-aos-delay="200">
           
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage03} width={260} height={260} alt="Testimonial 03" />
                
                  </div>
                  {/* Acme Inc. logo */}
   
              
      
            </article>
            {/* 4th Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]" data-aos-delay="300">
       
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage04} width={260} height={260} alt="Testimonial 04" />
                
                  </div>
             
          
            </article>
            {/* 5th Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]" data-aos-delay="400">
             
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage05} width={260} height={260} alt="Testimonial 05" />
                
                  </div>

            </article>
            {/* 6th Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]" data-aos-delay="500">
             
                  <div className="relative m-5">
                    <img className="rounded-full shrink-0" src={TestimonialImage06} width={260} height={260} alt="Testimonial 06" />
                
                  </div>
                  {/* Acme Inc. logo */}
   
          
            </article>
          </div>
   
        </div>
      </div>
    </section>
  )
}