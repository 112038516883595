// import Image from 'next/image'
// import TestimonialImage01 from '@/public/images/testimonial-01.jpg'
// import TestimonialImage02 from '@/public/images/testimonial-02.jpg'
// import TestimonialImage03 from '@/public/images/testimonial-03.jpg'
// import TestimonialImage04 from '@/public/images/testimonial-04.jpg'
// import TestimonialImage05 from '@/public/images/testimonial-05.jpg'
// import TestimonialImage06 from '@/public/images/testimonial-06.jpg'
import SpecImg from '../images/images/specific-img01.jpg'

export default function Testimonials() {  
  return (
    <section className="bg-slate-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-playfair-display text-slate-800">Typical Stop Loss Contract Periods</h2>
          </div>
          {/* Testimonials container */}
          <div className="max-w-sm mx-auto sm:max-w-none grid gap-12 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 sm:gap-y-8 items-start mb-12 md:mb-16" data-aos-id-testimonials>
            {/* 1st Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]">
              <header>
                <div className="flex items-center mb-4">
{/*  
                    <img className="rounded-full shrink-0" src={SpecImg} width={48} height={48} alt="Testimonial 01" /> */}
                    <div className="absolute top-0 right-0 -mr-2" aria-hidden="true">
                      <svg className="fill-blue-600" width="20" height="16" viewBox="0 0 20 16">
                        <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                      </svg>
                    </div>
                  </div>
                  {/* Acme Inc. logo */}
                  <h1>24/12:</h1>
     
              </header>
              <div className="grow mb-3">
                <p className="text-slate-500 italic">  Employer plan claims are covered by the Stop Loss policy only if they are incurred within 12 months prior to the effective date of the policy and paid during the policy term.</p>
              </div>
              {/* <footer className="text-sm font-medium">
                <a className="text-slate-800 hover:text-blue-600 transition duration-150 ease-in-out" href="#0">Patrick Hills</a>
                <span className="text-slate-300"> · </span>
                <span className="text-slate-500">CEO, Acme Inc.</span>
              </footer> */}
            </article>
            {/* 2nd Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]">
              <header>
                <div className="flex items-center mb-4">
{/*  
                    <img className="rounded-full shrink-0" src={SpecImg} width={48} height={48} alt="Testimonial 01" /> */}
                    <div className="absolute top-0 right-0 -mr-2" aria-hidden="true">
                      <svg className="fill-blue-600" width="20" height="16" viewBox="0 0 20 16">
                        <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                      </svg>
                    </div>
                  </div>
                  {/* Acme Inc. logo */}
                  <h1> 12/12:</h1>
     
              </header>
              <div className="grow mb-3">
                <p className="text-slate-500 italic">  Employer plan claims are covered by the Stop Loss policy only if they are incurred and paid during the policy term; this is a common first-year-only transition contract for larger, fully insured employers and commonly renews into a 24/12 contract to avoid a gap in coverage.</p>
              </div>
              {/* <footer className="text-sm font-medium">
                <a className="text-slate-800 hover:text-blue-600 transition duration-150 ease-in-out" href="#0">Patrick Hills</a>
                <span className="text-slate-300"> · </span>
                <span className="text-slate-500">CEO, Acme Inc.</span>
              </footer> */}
            </article>

            {/* 3rd Testimonial */}
            <article className="h-full flex flex-col bg-white p-6 shadow-xl" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonials]">
              <header>
                <div className="flex items-center mb-4">
{/*  
                    <img className="rounded-full shrink-0" src={SpecImg} width={48} height={48} alt="Testimonial 01" /> */}
                    <div className="absolute top-0 right-0 -mr-2" aria-hidden="true">
                      <svg className="fill-blue-600" width="20" height="16" viewBox="0 0 20 16">
                        <path d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z" />
                      </svg>
                    </div>
                  </div>
                  {/* Acme Inc. logo */}
                  <h1>12/15:</h1>
     
              </header>
              <div className="grow mb-3">
                <p className="text-slate-500 italic">  Employer plan claims are covered by the Stop Loss policy only if they are incurred during the policy term and paid within 90 days of the end of the policy term.</p>
              </div>
              {/* <footer className="text-sm font-medium">
                <a className="text-slate-800 hover:text-blue-600 transition duration-150 ease-in-out" href="#0">Patrick Hills</a>
                <span className="text-slate-300"> · </span>
                <span className="text-slate-500">CEO, Acme Inc.</span>
              </footer> */}
            </article>

            {/* 4th Testimonial */}

            {/* 5th Testimonial */}

            {/* 6th Testimonial */}

          </div>
          {/* See All Customers */}
          {/* <div className="text-center">
            <button className="btn text-white bg-blue-600 hover:bg-blue-700 group">
              See All Customers <span className="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  )
}