import React from "react"
import HeroResources from "../../sections/resources-sections/hero-resources";
import FormsList from "../../sections/resources-sections/forms-section";
import BusinessDesc from "../../sections/resources-sections/business-desc";
import BFaq from "../../sections/resources-sections/brmuw-faq";
import CompanyOwnership from "../../sections/resources-sections/company-ownership";

const ResourcesPage = (props) => {
  return (
    <div className="pb-8">
      <HeroResources />
      <FormsList />
      {/* <BFaq /> */}
      <BusinessDesc />
    <CompanyOwnership />
    </div>
  )
};

export default ResourcesPage;
