import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import TeamImage from '../../images/images/team.png'
import { useRecoilValue } from 'recoil'
import { teamMemberState } from '../../recoil/team'
import { teamData } from './team-data'


export default function TeamMemberHero(teamMember) {

  const location = useLocation()  

console.log('a',teamMember)

  return (
    <div className="bg-white">


      <main>
        <div>
          {/* Hero card */}
          <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">

                <div className="absolute inset-0">
                  <img
                    className="h-full w-full object-cover"
                    src={TeamImage}
                    alt="People working on laptops"
                  />
                  <div className="absolute inset-0 bg-slate-700 mix-blend-multiply" />
                </div>
                <div className="relative px-6 py-16 sm:py-12 lg:px-8 lg:py-32">
                  <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                    <span className="block text-white">{location.state.name}</span>
                  
                  </h1>
                  <h3 className='text-center text-white'> {teamMember[0]?.position}</h3>
               
                  <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
         
                  </div>
                </div>
        
            </div>
          </div>

       
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}
