
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import ContactList from '../../sections/contact-list'
import React, { useState } from "react";
import * as emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Example() {
  const notify = (message) => toast(message);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: { name: '', message: '', email: '' }
  });
  const sendEmail = (formData) => {
    emailjs
      .send("service_qud67wh", "template_px65ils", formData, 'dbmtecgP5UkJtPvvY')
      .then(
        (result) => {
          console.log(result.text);
          notify('Message sent')
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendEmail)}>
        <section class="text-gray-600 body-font relative">
        <ToastContainer />

          <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
            <div
              class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
              <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0"
                marginwidth="0" scrolling="no"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12218.903597218592!2d-75.1815842!3d40.0368988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6b84962a15555%3A0x7c96415ac889cceb!2sBRM%20Specialty%20Markets!5e0!3m2!1sen!2sus!4v1704833397788!5m2!1sen!2su"
              ></iframe>
              <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                <div class="lg:w-1/2 px-6">
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                  <p class="mt-1">600 W. Germantown Pike,
                    Suite 400, <br />
                    Plymouth Meeting, PA 19462</p>

                  <h2 class="title-font pt-2 font-semibold text-gray-900 tracking-widest text-xs">MAILING ADDRESS</h2>
                  <p class="mt-1">6020 Greene Street,
                    <br />
                    Philadelphia, PA 19144</p>
                </div>

                <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs" >EMAIL</h2>
                  <a class="text-blue-500 leading-relaxed" href = "mailto: info@brmuw.com">info@brmuw.com</a>
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                  <p class="leading-relaxed">(800) 782-8656</p>
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">FAX</h2>
                  <p class="leading-relaxed">(202) 840-8657</p>
                  <h2 class="title-font pt-2 font-semibold text-gray-900  text-xs">Please send forms to</h2>
                  <a class="text-blue-500 leading-relaxed" href = "mailto: rfp@brmuw.com">rfp@brmuw.com</a>
                </div>
              </div>
            </div>
            <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
              <h1 class="text-gray-900 text-3xl mb-1 font-bold title-font">Contact Us</h1>
              <p class="leading-relaxed mb-5 text-gray-600">Get In Touch
              </p>
              <div class="relative mb-4">
                <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                <input type="text" id="name" name="name"  {...register("name", { required: true })} class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                {errors.name && (
            <div style={{ color: "red" }} className="invalid-feedback d-block">
              Name is required.
            </div>
          )}
              </div>
              <div class="relative mb-4">
                <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                <input type="email" id="email" name="email" {...register("email", { required: true })} class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out " />
                {errors.email && (
            <div style={{ color: "red" }} className="invalid-feedback d-block">
              Email is required.
            </div>
          )}
              </div>
              <div class="relative mb-4">
                <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                <textarea id="message" name="message"
                  {...register("message", { required: true })}
                  class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                  
              </div>
              <button type='submit' class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send</button>


            </div>
          </div>
        </section>
      </form>
    </>
  )
}
