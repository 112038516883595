export default function HeroResources() {  
    return (
      <section className="relative">
  
        {/* Dark background */}
        <div className="absolute inset-0 bg-slate-900 pointer-events-none -z-10" aria-hidden="true">      <div
                    className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                    />
                </div></div>
  
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-12 pb-8 md:pt-12 md:pb-16">
  
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="h1 font-playfair-display text-slate-100 mb-4">Resources</h1>
              {/* <p className="text-xl text-slate-400 mb-8">Lessons designed to help you kick start and grow your business and turn your idea into a thriving empire.</p> */}
              {/* Subscribe form */}
              <form>
                {/* <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md">
                  <input type="email" className="form-input w-full bg-slate-800 border border-slate-700 focus:border-slate-600 text-white placeholder-slate-500 mb-2 sm:mb-0 sm:mr-2" placeholder="Your email" aria-label="What are you searching for?" />
                  <button className="btn text-white bg-blue-600 hover:bg-blue-700">Search</button>
                </div> */}
                {/* Success message */}
                {/* <p class="text-xs text-slate-500 mt-3 italic">Thanks for subscribing!</p> */}
              </form>
            </div>
  
          </div>
        </div>
      </section>
    )
  }