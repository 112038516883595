import React from "react"
import HeroFunding from "../../sections/hero-funding";

const CapitalPage = (props) => {
  return (
    <div>
  <HeroFunding />
    </div>
  )
};

export default CapitalPage;
