import React from "react"
import HeroUnderwriting from "../../sections/hero-underwriting";

const UnderwritingPage = (props) => {
  return (
    <div>
<HeroUnderwriting />
    </div>
  )
};

export default UnderwritingPage;
