import React from "react"

const TestimonialsPage = (props) => {
    return (

        <section class="bg-white dark:bg-gray-900">
            <div class="container px-6 py-10 mx-auto">
                <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
                    What our <span class="text-blue-500 ">clients</span> say
                </h1>

                {/* <p class="max-w-2xl mx-auto mt-6 text-center text-gray-500 dark:text-gray-300">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error
                    alias, adipisci rem similique, at omnis eligendi optio eos harum.
                </p> */}

                <section class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 lg:grid-cols-2 xl:grid-cols-3">
                    <div class="p-8 border rounded-lg dark:border-gray-700">
                        <p class="leading-loose text-gray-500 dark:text-gray-400">
                            “BRM is a refreshing addition to the MGU Stop Loss industry. Their approach of working with my sales team in developing creative risk management strategies and ideas has help me secure several New Business accounts. I also love the ability on occasions to discuss a prospect with their underwriting team. I feel like they “Get Us, the Broker Community”.
                        </p>

                        <div class="flex items-center mt-8 -mx-2">


                            <div class="mx-2">
                                <h1 class="font-semibold text-gray-800 dark:text-white">BRM PRODUCER PARTNER</h1>

                            </div>
                        </div>
                    </div>

                    <div class="p-8 border rounded-lg dark:border-gray-700">
                        <p class="leading-loose text-gray-500 dark:text-gray-400">
                            “The level of responsiveness that we receive from the BRM Underwriting team is impressive and very much appreciated. They work with us on setting up our RFP priorities to insure we get back to our clients on a timely basis. But, most importantly, they always provide us with a Fair proposal, and take the time to review their underwriting rationale.”.
                        </p>

                        <div class="flex items-center mt-8 -mx-2">


                            <div class="mx-2">
                                <h1 class="font-semibold text-gray-800 dark:text-white">BRM PRODUCER PARTNER</h1>

                            </div>
                        </div>
                    </div>

                    <div class="p-8 border rounded-lg dark:border-gray-700">
                        <p class="leading-loose text-gray-500 dark:text-gray-400">
                            “BRM Specialty Markets is a first class MGU. Their underwriters combine years of practical knowledge of the self-funded reinsurance industry. They know how to write and maintain good contracts. I always learn something new from them that helps me close cases. Not to mention they are able to deliver competitive quotes in a very timely manner. The people at BRM are a joy to work with.”.
                        </p>

                        <div class="flex items-center mt-8 -mx-2">


                            <div class="mx-2">
                                <h1 class="font-semibold text-gray-800 dark:text-white">BRM PRODUCER PARTNER</h1>

                            </div>
                        </div>
                    </div>
                    <div class="p-8 border rounded-lg dark:border-gray-700">
                        <p class="leading-loose text-gray-500 dark:text-gray-400">
                            “Working with self-funding and stop loss can be invigorating, frustrating and challenging, all rolled into one. To work it successfully, you need good partners particularly in the MGU space. A good partner is responsive when you have RFP’s and provides competitive rates. A great partner works with you on the the situation that doesn’t line up with the routine. That, to me, involves the art of active listening and coming up with solutions that treat all parties fairly.

                            We have been very happy with our relationship with BRM and their ability to work with us not only on the simple case but more importantly, the complex ones as well. By the definition in the preceding paragraph, you and BRM are great partners.

                            If there is a standard of performance one would look to in working with an MGU on stop loss, BRM would be among the best. I would highly recommend your services.”.
                        </p>

                        <div class="flex items-center mt-8 -mx-2">


                            <div class="mx-2">
                                <h1 class="font-semibold text-gray-800 dark:text-white">BRM TPA PARTNER</h1>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    )
}

export default TestimonialsPage;
