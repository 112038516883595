import RomanPhoto from '../../images/images/Roman-Head-Shot.jpg'
import RomanDescPhoto from '../../images/images/roman-back.jpg'
import AnthonyPosPhoto from '../../images/images/Anthony-img01.jpg'
import AnthonyDescPhoto from '../../images/images/anthony-sec02-img.jpg'
import CarriePosPhoto from '../../images/images/carrie-img01.jpg'
import CarrieDescPhoto from '../../images/images/carrie-sec02-img.jpg'
import GradyPosPhoto from '../../images/images/grady-1.jpg'
import GradyDecPhoto from '../../images/images/grady-img02.jpg'
import ShaynaPosPhoto from '../../images/images/Shayna-Actis-back.jpg'

export const teamData = [
  {
    id: 1,
    firstName: 'Anthony',
    lastName: 'Fasciano',
    position: 'Controller for BRM Specialty Markets',
    details:
      'Anthony Fasciano is a Finance & Accounting Professional based in Bucks County, PA.  He is the Controller for BRM Specialty Markets and has over 30 years of experience in the Insurance industry.  His journey in Insurance F&A began while he was still in college when he had accepted a job that he thought was just for the summer, little did he know it would be his passion for years to come.',
    position_image: AnthonyPosPhoto,
    desc_image: AnthonyDescPhoto,
    desc_title: 'He has worked for fortune 500 companies',
    desc_body:
      'In many different finance roles, at many different levels, which is what helped him to build his knowledge base & skillset.\n\nHe has worked for fortune 500 companies. He has been happily married for almost 30 years and is the proud father of two young men, both of whom live with Fragile X Syndrome.  He sits on the Board of Directors for the National Fragile X Foundation (NFXF) serving in the capacity of Treasurer. He is an avid fan of football, enjoys classic cars, some golf now and again, good bourbon and an occasional trip to Atlantic City with old friends.  He also is an animal lover and has a dog and two cats of his own that own his heart.',
    fun_facts: [],
  },
  {
    id: 2,
    firstName: 'Audrey',
    lastName: 'Weiser',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Policy/Underwriting Coordinator',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: 'This year will be the ninth.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer: 'Stephanie Santana 😊',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: 'My lovely colleagues.',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer:
          'Read books, watch TV/movies, go to concerts, try new restaurants, lounge on the couch, plan trips, hang with the cats, etc.',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer: 'Maynard James Keenan',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer: 'Give me a beach or throw me in a lazy river and I’m happy!',
      },
      {
        id: 8,
        question: 'What’s at the top of your bucket list?',
        answer: 'Whale watching in Newfoundland.',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer: 'I love all animals, but cats are the best.',
      },
    ],
  },
  {
    id: 3,
    firstName: 'Beverly',
    lastName: 'Beebe',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'My role at BRM is a Claims Assistant',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: 'I have been in the industry for 11 years.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer:
          'I came to BRM through former colleagues who were fortunate to have joined the BRM team previously.',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer:
          'The thing I like best about my job is the energy, culture and most of all the people at BRM.',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer: 'For fun I like to garden, paint and spend time with family and friends.',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer:
          'My biggest inspiration is my father. He set a great example for his nine children.',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer: 'The best trip I’ve ever taken was a trip to Barbados.',
      },
      {
        id: 8,
        question: 'What’s at the top of your bucket list?',
        answer: 'Top of my bucket list is road tripping with my husband, once I retire.',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer:
          'I am a dog person. Currently, I have three grand dogs, two Golden Retrievers and a Red Fox lab, all under two years old.',
      },
    ],
  },
  // {
  //   "id": 4,
  //   "firstName": "Brandy",
  //   "lastName": "Schaffer",
  //   "position": null,
  //   "details": null,
  //   "desc_title": null,
  //   "desc_body": null,
  //   "fun_facts": [
  //     {
  //       "id": 1,
  //       "question": "What is your role at BRM?",
  //       "answer": "My current role at BRM is Finance Coordinator, enabling me the opportunity to work with our valued Broker and TPA partners on a regular basis."
  //     },
  //     {
  //       "id": 2,
  //       "question": "How long have you been in this industry?",
  //       "answer": "Brandy began her career in the fully insured marketplace at Blue Cross of Northeastern Pennsylvania (BCNEPA) in 1999, where she worked closely with Clinical Affairs to update medical policies in accordance with essential health benefits and legislative directives. In March 2015, she accepted a position in Operations with Montgomery Management Corporation (MMC), an underwriter of employer stop-loss coverage. In this position, she had the opportunity to work across most facets of the industry including, underwriting, compliance and finance. Subsequently, she joined BRM Specialty Markets, LLC in October of 2019 as one of the first employees. Brandy received her Business Administration Degree from a local college in her hometown of Wilkes-Barre, PA. Additionally, she furthered her education in the medical field as a Nationally Certified Medical Assistant (NCMA) and currently holds a Certified Professional Coder with ICD-10 certificate from the American Academy of Professional Coders (AAPC)."
  //     },
  //     {
  //       "id": 3,
  //       "question": "What drew you to this space?",
  //       "answer": "Having a lengthy career in the insurance industry, it was my goal to continue to work for a forward-thinking organization with strong leadership and a vision for future growth and development."
  //     },
  //     {
  //       "id": 4,
  //       "question": "What do you like best about your job?",
  //       "answer": "I thoroughly enjoy the everchanging landscape of our industry. There is always an opportunity to develop new relationships, learn different tasks or help a team member develop new skills."
  //     },
  //     {
  //       "id": 5,
  //       "question": "What do you do for fun?",
  //       "answer": "For fun, I enjoy time with family and friends, shopping and traveling."
  //     },
  //     {
  //       "id": 6,
  //       "question": "What is something we would be surprised to know about you?",
  //       "answer": "N/A – lol, I think everyone knows everything about me… he he"
  //     },
  //     {
  //       "id": 7,
  //       "question": "Describe the best trip you’ve ever taken?",
  //       "answer": "The most enjoyable trip I’ve ever had the pleasure of taking was hiking and canyoneering at Zion National Park, Utah in the winter of 2013 when crowds are at a minimum and the scenery is otherworldly."
  //     },
  //     {
  //       "id": 8,
  //       "question": "What’s at the top of your bucket list?",
  //       "answer": "At the top of my bucket list would have to be a trip to the Galápagos Islands to snorkel with the sea turtles and experience the untouched beauty of the protected habitat."
  //     },
  //     {
  //       "id": 9,
  //       "question": "Are you a cat or dog person?",
  //       "answer": "I am a cat person, if I could save them all, I would!"
  //     }
  //   ]
  // },
  {
    id: 5,
    firstName: 'Carrie',
    lastName: 'Urbanelli',
    position: 'CHIEF UNDERWRITING OFFICER, BRM SPECIALTY MARKETS',
    details:
      'Carrie Urbanelli is a stop-loss insurance professional with over 20 years of industry experience. She has been the Chief Underwriting Officer at BRM Specialty Markets since October 2019. Prior to this, she spent fifteen years at Montgomery Management MGU: as President most recently, and Chief Underwriting Officer from 2008-2015.',
    position_image: CarriePosPhoto,
    desc_image: CarrieDescPhoto,
    desc_title: 'During her tenure',
    desc_body:
      'She worked extensively with multiple product lines: traditional stop-loss, small group aggregate only, large group MEC and college plans, as well as various captive programs.\n\nShe has completed courses toward her CEBS designation and served on committees at the Self-Insurance Institute of America and Children’s Crisis Treatment Center in Philadelphia. She has also acted as a mentor for Temple University’s Risk Management program and the Achieving Independence Center. She holds a BBA in Risk Management/Insurance and Human Resources Management, and an MBA with a concentration in Insurance from Temple University.She lives in Blue Bell with her husband and two young boys. When she is not working and spending time with family, she enjoys traveling, yoga and serving the community.',
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Chief Underwriter',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: '23 years',
      },
      {
        id: 3,
        question: 'What do you like best about your job?',
        answer:
          'I love the people I work with, both at BRM and our outside partners, and I love assessing risk because I’m a giant nerd',
      },
      {
        id: 4,
        question: 'What do you do for fun?',
        answer:
          'My life revolves around my family so anything we do together is fun – we love playing sports and games together. Outside of them, I enjoy reading and yoga.',
      },
      {
        id: 5,
        question: 'Are you a cat or dog person?',
        answer:
          'I have always been a cat person, but we added a puppy to our family in the past year and I’m completely in love with her, so now I would say I’m a dog person😊',
      },
    ],
  },
  {
    id: 6,
    firstName: 'Eboni',
    lastName: 'Scott',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Administrative Manager',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: '10.5 months',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer: 'Opportunity to work in a new industry',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: 'My co-workers',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer: 'I love to travel, and I go to comedy shows.',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer: 'My children',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer: 'I travel a lot so it’s hard to pick just one.',
      },
      {
        id: 8,
        question: 'What’s at the top of your bucket list?',
        answer: 'To travel around Europe',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer: 'Both, but my dog holds a special place in my heart.',
      },
      {
        id: 10,
        question: 'Is there anything else we should know about you?',
        answer:
          'I love to laugh and believe that happiness is a choice that one needs to make every day.',
      },
    ],
  },
  {
    id: 7,
    firstName: 'Grady',
    lastName: 'Ford',
    position: 'Partner',
    details:
      'Grady Ford has more than 40 years of experience in the financial services and employee benefits industry. In June 2019 Grady Ford co-founded BRM Specialty Markets, LLC, a Managing General Agency, offering Stop-Loss coverage in 50 states across the USA. He currently serves as the Chief Marketing Officer, developing new markets and opportunities for the firm. His reputation and strong relationships in the Broker Community has set the company apart from other MGU’s.',
    position_image: GradyPosPhoto,
    desc_image: GradyDecPhoto,
    desc_title: 'Prior to Co-Founding BRM',
    desc_body:
      'Grady Ford was instrumental in the development of Benefyx, a self-funded cooperative between Aetna Healthcare, United Healthcare, Meritain TPA, UMR and Montgomery Management (MGU), which offers competitive Healthcare coverage to institutions in all 50 States.\n\nOver his tenure, Grady Ford has served as an underwriting manager for Allstate Insurance Company in Murray Hill, NJ, Broker Coordinator for Blue Cross and Blue Shield in their Corporate and National Accounts Division, Managing consultant for the NJ Licensed Beverage Association Medical plan (a self-funded plan established by Grady Ford on behalf of the Association), Key Account Executive for USI (a Goldman-Sachs Company), and Co-Founder of his own Consulting Firm, Consultants for Corporate Benefits, Inc., located in downtown Newark, which was subsequently sold to Commerce Bank in 2005.\n\nIn addition to his existing clientele, the list of clients Grady Ford has managed over the years include NJ Transit, Seton Hall University, The City of Newark, The Newark Housing Authority, The Newark Public Schools, the Newark Museum, The Township of Irvington, The Verona Board of Education, PSEG and The Revlon Corporation, NJ Transit Rail, and Anheuser-Busch to name a few.',
    desc_title2: 'Revlon Corporation',
    desc_body2:
      'Grady Ford is extremely knowledgeable in risk management, underwriting, plan designs, funding arrangements, and compliance requirements under the Affordable Care Act (Obama Care). He has participated as an advisor or information resource during labor negotiation with several of his clients, including Revlon Corporation and the City of Newark.\n\nGrady Ford’s reputation in the industry is well known. In 2002 he was asked to sit on the Aetna US Healthcare producer advisory board and received a US Senate Citation for providing excellence in professional achievement. Grady Ford has served on the Board of the Regional Business Partnership in Newark, Chaired the Fund Development committee on the Board of Director of the Girl Scout Council of Greater Essex and Hudson Counties. Grady Ford is a current member of the African American Chamber of Commerce and on the Advisory Board for “Preserve our Legacy”, and a member of the National Association of Health Underwriters..\n\nAs a member of the US Air-Force Reserves, Grady Ford has served his country in support of Operation Desert Storm and Operation Iraqi Freedom. After completing 27 years of honorable service, Grady Ford retired from the Air Force Reserves in May of 2006.',
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer:
          'Chief Marketing Officer – Responsible for New Business Sales and Strategic Market development and outreach',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: '43 years',
      },
      {
        id: 3,
        question: 'What do you like best about your job?',
        answer:
          'I love working with clients, prospects, and Brokers in the development of creative financial solutions for their corporate employee benefits programs.',
      },
      {
        id: 4,
        question: 'What do you do for fun?',
        answer:
          'I love traveling with both friends and family to experience other cultures. When traveling, I like to stray away from the tourist areas and visit with people in their neighborhoods.',
      },
      {
        id: 5,
        question: 'Are you a cat or dog person?',
        answer:
          'I am definitely a dog person. Unfortunately, due to allergies I cant have them in the house.',
      },
    ],
  },
  {
    id: 8,
    firstName: 'Molly',
    lastName: 'Lyons',
    position: 'Underwriting Coordinator',
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Underwriting Coordinator',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: 'About 6 years the ninth.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer: 'The great people here to work with.',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: 'Probably the atmosphere and co-workers.',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer: 'Painting portraits, making people laugh and hanging out with friends',
      },
      {
        id: 6,
        question: 'What is something we would be surprised to know about you?',
        answer: 'I met Beyonce in 2001',
      },
      {
        id: 7,
        question: 'What’s at the top of your bucket list?',
        answer: 'To get to Hawaii soon',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer: 'I was born a dog person but now I have a cat, so I am both',
      },
    ],
  },
  {
    id: 9,
    firstName: 'Roman',
    lastName: 'McDonald',
    position: 'CHIEF EXECUTIVE OFFICER, BRM SPECIALTY MARKETS',
    details:
      'Roman McDonald, Sr., Prior to co-founding BRM Specialty Markets in 2019, was the Founder and CEO of First Genesis Benefits Consulting (dba Benefyx), a division of First Genesis Holdings, Inc. Roman McDonald has more than 20 years of experience in the insurance and financial services industry.   First Genesis holdings had a suite of complimentary companies that concentrated in the Insurance and Financial Services industries, First Genesis Financial Group, First Genesis Benefits Consulting (dba, Benefyx), and First Genesis Risk Management.',
    position_image: RomanDescPhoto,
    desc_image: RomanPhoto,
    desc_title: 'Prior to establishing First Genesis Holdings',
    desc_body:
      'Roman McDonald’s passion and enthusiasm towards insurance and financial services has remained consistent throughout the changes within these industries.  This passion led him to identify a void in the marketplace after working exclusively with small to mid-size companies during his year tenure with Creative Financial Group, one of the largest distribution companies for the MetLife Enterprise.  It was there where Roman developed a business model that supports the financial services and insurance needs of small to mid-size companies, ultimately evolving into First Genesis Holdings, Inc.\n\nAfter earning a Bachelor of Science degree from Lehigh University, Roman began his career in the investment arena while working on the trading desk for DLJ Direct, the online division of Donaldson, Lufkin, and Jenrette.  As an advocate for helping individuals and businesses meet their financial goals and objectives; Roman has been able to transform his practice from a trusted and respected resource for his community and client base, to a regional based trusted and respected brand of companies within the industry.  His passion and enthusiasm for helping others continues to place him in demand as a speaker throughout the country.   Roman McDonald has been recognized by the Philadelphia Business Journal as one of the Top 40 Business Leaders under the age of 40 in 2009 and was featured in August 2015 issue of Smart CEO Magazine.\n\nHe currently is a member of the National Association of Insurance and Financial Advisors, and The Greater Philadelphia Association of Health Underwriters.  Roman is also a member of Kappa Alpha Psi Fraternity, Inc. and the National Association of Guardsmen Inc. Roman resides in Philadelphia, PA with his wife Dr. Elana O. McDonald and their two children.',
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'CEO',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: '25 + years',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer:
          'An evolution of my career from being on a trading desk for Equity and Option trading….to financial services….. to employee benefits. I have always had an entrepreneurial spirit and I am truly blessed to have been able to find such great partners to develop BRM.',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: 'Solving Problems and Helping People',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer:
          'Travelling, Spending time with my family, friends and my dog, and attending sporting events.',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer: 'My wife and kids',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer: 'Bali, Indonesia. It was an amazing cultural and culinary experience.',
      },
      {
        id: 8,
        question: 'What’s at the top of your bucket list?',
        answer: 'To visit various countries in Africa',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer: 'Dog',
      },
      {
        id: 10,
        question: 'What is something we would be surprised to know about you?',
        answer: 'I LOVE to cook for people that are near and dear to me.',
      },
      {
        id: 1,
        question: 'Is there anything else we should know about you?',
        answer: 'I love Philly Sports!!!!!',
      },
    ],
  },

  {
    id: 10,
    firstName: 'Shayna',
    lastName: 'Actis',
    position: 'DIRECTOR OF CLAIMS',
    details:
      'My role at BRM is Director of Claims and I have been in the claims industry for 10 years. I started out as a Claims Assistant where I entered all claims data into the system. I then moved onto Claims Auditor, then became a Claims Supervisor. What I like best about my job are the people I work with, flexibility and the ability to work from home. My career path sort of fell into my lap one day when I was looking for a job. As an insurance professional there is a ton to learn and I strive to be knowledgeable just by being an active listener. I am enjoying the new experiences and connecting with new people.\n\nI have two children, both girls and when I’m not running them all over the place, I enjoy relaxing at home with my dog Jax and watching Netflix. I enjoy traveling and the best trip I ever took was to Bermuda. Bora Bora is at the top of my bucket list, but most recently is to take my girls on a snorkeling trip!',
    position_image: ShaynaPosPhoto,
  },
  {
    id: 11,
    firstName: 'Sieanna',
    lastName: 'Rahatt',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer:
          'Here at BRM, I’m an underwriting coordinator, but I also help with the processing of claims reimbursements.',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer:
          'I’ve actually only been in the industry for about six months now! It’s been a long learning experience but I’m so thankful that BRM took a chance on me and taught me all that I need to know!',
      },
      {
        id: 3,
        question: 'Is there anything else we should know about you?',
        answer: 'I’m just happy to be here',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer:
          'The best part about my job is the people! I know people say that all the time, but this is truly the best batch of people I know! I love coming into the office on Mondays because that means we get to laugh together, share our weekends, and overall just have a good time!',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer:
          'I have lots of different hobbies, particularly in the arts! When I first joined BRM, I was also working at a summer theatre as an actress/dancer at Northampton Community College’s summer theatre. I also am an assistant coach at my alma mater’s color guard team, which I was formerly a member and captain of back when I was still in high school. In my downtime, I enjoy drawing, video production, writing, reading, and any other art forms I have the opportunity to explore!',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer:
          'My mom is my biggest inspiration! She is currently an underwriter at IISI, but, besides that, she is the strongest person I know, as well as being my best friend! She’s always been there for me and has always inspired me to be true to myself and spread kindness to those around me.',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer:
          'I’ve taken an abundance of great trips, but I think my favorite was a two-week long trip to Japan between my junior and senior year of college. I went with my high school class, and we had the opportunity to travel Tokyo, Okinawa, and Kyoto, Okinawa being my favorite of the three! There, we stayed with a host family and went to Futenma High School as our host school. My host sister and I stay in touch, and she is very supportive of my slow yet continued growth in learning the Japanese language!',
      },
      {
        id: 8,
        question: 'What is something we would be surprised to learn about you?',
        answer: 'I was a child model for Crayola Color Fillz when I was ten.',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer:
          'I love both very much, but I’m definitely a cat person! I appreciate that their love is not unconditional, so you know that if a cat is comfortable around you it is because they truly like you! I also love that they’re barely domesticated- they can take care of themselves! The reason that they’re in your house is because they know you’re the easiest place to get food, but they also think you’re kind of cool so they stick around. I think that’s neat.',
      },
    ],
  },
  {
    id: 12,
    firstName: 'Silvana',
    lastName: 'Herbert',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Director of Underwriting',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: '10 years',
      },
      {
        id: 3,
        question: 'What do you like best about your job?',
        answer:
          'I love the process of underwriting and evaluating risk, but I also love the people I work with and the clients we get to help!',
      },
      {
        id: 4,
        question: 'What do you do for fun?',
        answer:
          'I love going to the gym with my daughter! Hanging out with my husband and kids is always a good time.  Hiking and being outside is the absolute best!!',
      },
      {
        id: 5,
        question: 'Whho is your biggest inspiration?',
        answer:
          'This is a trio that has had the biggest impact on my life.  My husband, son and daughter.  All for different reasons but at their core, they are the kindest and most authentic people and have made me who I am.',
      },
      {
        id: 6,
        question: 'Describe the best trip you’ve ever taken?',
        answer:
          'Turks and Caicos is probably my favorite place on earth.  The beach is crystal clear, the entire feel of the place is so peaceful, calming, and beautiful.  Meditating on the beach at 6am when mostly everyone else around was asleep, was a lasting memory for me!',
      },
      {
        id: 8,
        question: 'Are you a cat or dog person?',
        answer: 'I have had both in the past but if I had to pick, I’d say dog.',
      },
    ],
  },

  {
    id: 13,
    firstName: 'Margie',
    lastName: 'Heron',
    position: 'Underwriting Coordinator',
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'Underwriting Coordinator',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: ' Brand new to the industry.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer: 'A good friend ',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: ' People and the opportunity for growth',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer: 'Spend time with my kids, plants and gardening, crafts',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer: 'My parents',
      },
      {
        id: 7,
        question: 'Describe the best trip you’ve ever taken?',
        answer: ' Going to California with my Mom and sisters',
      },
      {
        id: 8,
        question: 'What’s at the top of your bucket list?',
        answer: 'Skydiving',
      },
      {
        id: 9,
        question: 'Are you a cat or dog person?',
        answer: 'Plant person',
      },
    ],
  },
  {
    id: 14,
    firstName: 'Gina Marie',
    lastName: 'D’Auria',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: 'I am a Junior Underwriter',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: ' I’ve been in self-funded stop loss for almost 4 years.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer:
          ' What drew me into this space was the people I met along the way. Everyone I’ve encountered on my journey has been kindly welcoming and eager to teach, which works great because I’m eager to learn!',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer:
          ' I enjoy having structure with data sets but also having an edge of creativity when it comes to underwriting.',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer:
          'I teach kids how to ice skate! I was a competitive figure skater growing up, now I teach classes and private lessons on the weekend. I also artistic inline skate. I enjoy thrifting as well.  ',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer:
          ' I’m inspired by women in this industry who have careers and a strong family life. I’ve always aspired to do both and being led and taught by women who have accomplished both is very inspiring for me.',
      },
      {
        id: 8,
        question: 'Describe the best trip you’ve ever taken?',
        answer:
          'I don’t travel much, but in the summer you’re most likely to find me at the Jersey Shore.',
      },
      {
        id: 9,
        question: "What's at the top of your bucket list?",
        answer: 'I would love to go to Australia.',
      },
      {
        id: 10,
        question: 'Are you a cat or dog person?',
        answer: 'CAT. I have a 4-year-old cat named Blondie.',
      },
    ],
  },
  {
    id: 15,
    firstName: 'Jeremy',
    lastName: 'Walker',
    position: null,
    details: null,
    desc_title: null,
    desc_body: null,
    fun_facts: [
      {
        id: 1,
        question: 'What is your role at BRM?',
        answer: ' Finance Coordinator',
      },
      {
        id: 2,
        question: 'How long have you been in this industry?',
        answer: 'New to this Industry.',
      },
      {
        id: 3,
        question: 'What drew you to this space?',
        answer: 'Type of work accompanied by the group of people Im allowed to work with',
      },
      {
        id: 4,
        question: 'What do you like best about your job?',
        answer: 'Work from Home and easy going atmosphere',
      },
      {
        id: 5,
        question: 'What do you do for fun?',
        answer: 'Sports, Gaming, Relaxing',
      },
      {
        id: 6,
        question: 'Who is your biggest inspiration?',
        answer: 'My mother and sister',
      },
      {
        id: 8,
        question: 'Describe the best trip you’ve ever taken?',
        answer: 'Puerto Rico National Rainforest',
      },
      {
        id: 9,
        question: "What's at the top of your bucket list?",
        answer: 'visit the Pyramids in Egypt',
      },
      {
        id: 10,
        question: 'Are you a cat or dog person?',
        answer: "Neither doesn't matter",
      },
    ],
  },
]
