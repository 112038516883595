import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function CompanyOwnership() {
  return (

     <>
     <div className="mx-auto -mt-12 max-w-7xl py-12 px-6 sm:mt-0 lg:px-8 xl:-mt-8">
     <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none mt-8">
       <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Company Ownership/Legal Entity</h2>
       <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
    
         <div className="lg:w-full lg:max-w-2xl lg:flex-auto">

         <p className="text-xl leading-8 text-gray-600">
           BRM Specialty Markets is a Delaware based LLC. BRM is a Veteran and Minority Owned Medical Stop Loss Managing General Underwriter (MGU). BRM will have access to unique markets that require minority participation in all contracts and RFP’s over a specified value. This typically includes governmental entities, Federal Agencies, and Fortune 500/1000 firms. As a business model, we do not lead with our Disabled Veteran or Minority status, but recognized its value, and are prepared to take advantage of the many opportunities available both Regional and National.
           </p>
           <div class='p-4'>
 
 <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-xl">Location</h2>

   <p class='pb-4'>The corporation headquarters for BRM Specialty Markets will be 600 West Germantown Pike, Suite 400, Plymouth Meeting, PA 19462</p>
   <p>With a second location at 6020 Greene Street, Philadelphia, PA 19144.</p>
   </div>
         </div>
         <div className="lg:flex lg:flex-auto lg:justify-center">
         <div class="relative w-full h-96">
    <iframe class="absolute top-0 left-0 w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12218.903597218592!2d-75.1815842!3d40.0368988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6b84962a15555%3A0x7c96415ac889cceb!2sBRM%20Specialty%20Markets!5e0!3m2!1sen!2sus!4v1704833397788!5m2!1sen!2su" 
        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
</div>

         </div>
       </div>
     </div>
   </div>
   </>
   
  )
}
