import React from "react"

const ClaimsPage2 = (props) => {
  return (
    <div>
      claims
    </div>
  )
};

export default ClaimsPage2;
