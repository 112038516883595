// export const metadata = {
//     title: 'Pricing - Tidy',
//     description: 'Page description',
//   }
  
  import Hero from '../../sections/hero-pricing'
  import CtaPricing from '../../sections/cta-pricing'
  import Features from '../../sections/features-pricing'
  import FeaturesTable from '../../components/features-table'
  import Faqs from '../../sections/faqs'
  import Cta from '../../sections/cta-dark'
  
  export default function ProductPage() {
    return (
      <>
        <Hero />
        <CtaPricing />
        <Features />
        {/* <FeaturesTable /> */}
        {/* <Faqs /> */}
        <Cta />
      </>
    )
  }
  