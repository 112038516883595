import React from "react"
import { useNavigate } from 'react-router-dom';
import PDFView from "../../components/PDFViewer";
import ClaimsImage from '../../images/images/claim-kit.jpg'
import ApprovalImage from '../../images/images/tpa-approval.jpg'
import SpanishImage from '../../images/images/extra.jpg'
import SpanImage from '../../images/images/spetic-img02.jpg'
import IMGImage from '../../images/images/form-box03.jpg'
import RFPImage from '../../images/images/form-box04.jpg'
import Form6Image from '../../images/images/form-box05.jpg'


const FormsList = (props) => {
    const navigate = useNavigate()
    const pdfNavigate = (fileName, fileUrl) => {

      navigate(`/forms/${fileName}`, { state: {fileUrl: fileUrl}})
    }
  return (
<>

    <div class="grid-cols-1 sm:grid md:grid-cols-3 mx-3 p-6">
      
      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" >
        <a href="#!">
        <img
            class="rounded-t-sm"
            src={ClaimsImage}
            alt="claims kit" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
         BRM Claims Kit
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                    pdfNavigate('Claims-kit', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-Claims-Kit-Fillable_Rebranded-1.pdf?alt=media&token=0970489c-a225-4e88-bb97-8bcfb82f570e')
                  }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>
      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" data-aos-delay="200">
        <a href="#!">
          <img
            class="rounded-t-sm"
            src={ApprovalImage}
            alt="approval" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
            TPA Approval Questionnaire
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                    pdfNavigate('TPA-approval', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-TPA-Approval-Questionnaire_Rebranded.pdf?alt=media&token=82136972-4967-4f4d-ae69-da049999972b')
                  }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>
      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" data-aos-delay="400">
        <a href="#!">
          <img
            class="rounded-t-sm"
            src={SpanishImage}
            alt="Skyscrapers" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
            BRM Spanish IMQ Form
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() =>{
                    pdfNavigate('spanish-imq-form', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-Spanish-IMQ-Fillable.pdf?alt=media&token=0340e213-d066-4f6c-a177-48ae4c1cf49a')
                }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>
      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" >
        <a href="#!">
          <img
            class="rounded-t-sm"
            src={IMGImage}
            alt="Los Angeles Skyscrapers" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
       BRM IMQ FORM
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() =>{
                    pdfNavigate('imq-form', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/BRM-IMQ.pdf?alt=media&token=9ab03baf-8140-4f07-b39c-30326dbc0a06')
                }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>

      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" data-aos-delay="200">
        <a href="#!">
          <img
            class="rounded-t-sm"
            src={RFPImage}
            alt="Los Angeles Skyscrapers" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
       BRM RFP CHECKLIST
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() =>{
                    pdfNavigate('rfp-checklist', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/RFP-Checklist.pdf?alt=media&token=7e973e33-6dcb-4f6f-be60-e5b9c6eff97c')
                }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>

      <div
        class="mx-3 mt-6 flex flex-col self-start rounded-sm bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 sm:shrink-0 sm:grow sm:basis-0" data-aos="fade-up" data-aos-delay="400">
        <a href="#!">
          <img
            class="rounded-t-sm"
            src={Form6Image}
            alt="Los Angeles Skyscrapers" />
        </a>
        <div class="p-6">
          <h5
            class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
            BRM DISCLOSURE FORM
          </h5>
          <div class='pt-4'>
              <button
                type="submit"
                className="flex w-full justify-center rounded-sm bg-blue-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() =>{
                    pdfNavigate('disclosure', 'https://firebasestorage.googleapis.com/v0/b/brmuw-bb0bc.appspot.com/o/Disclosure.pdf?alt=media&token=fb2a3f96-ab3a-423c-9245-b68227d92154')
                }}
              >
                Download Form
              </button>
            </div>
        </div>
      </div>

    </div>
    <div class='w-full'>
   
    </div>
    </>
  )
};

export default FormsList;
