// export const metadata = {
//     title: 'About - Tidy',
//     description: 'Page description',
//   }
  
  import Hero from '../../sections/hero-about'
  import Stats from '../../sections/stats-02'
//   import AboutContent from './content'
  import Team from '../../sections/team'
  import TeamMembers from '../../sections/team-members'
  import Clients from '../../sections/clients-02'
  import Cta from '../../sections/cta-02'
  import AboutContent from '../../sections/about-content'
  import CtaDark from '../../sections/cta-dark'
  
  export default function About() {
    return (
      <>
        <Hero />

        <Stats />
        <AboutContent />
        <Team />
        <TeamMembers />
        {/* <Clients /> */}
        <CtaDark />
      </>
    )
  }
  