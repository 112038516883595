// import Link from 'next/link'
import { Link } from 'react-router-dom'
// import VideoThumb from '@/public/images/hero-image-01.jpg'
import ModalVideo01 from '../components/modal-video-01'
import BRMHome from '../images/images/brm-home-banner.jpg'
import HeroImage from '../images/images/mangement-img.jpg'


// export default function HeroHome() {
//   return (
//     <section className="relative">

//       {/* Dark background */}
//       <div className="absolute inset-0 bg-slate-900 pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]" aria-hidden="true"></div>
      

//       <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
//         <div className="pt-32 pb-20 md:pt-40 md:pb-44">

//           {/* Hero content */}
//           <div className="max-w-xl mx-auto md:max-w-none md:flex md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 md:space-y-0">

//             {/* Content */}
//             <div className="text-center md:text-left md:min-w-[30rem]" data-aos="fade-right">
//               <h1 className="h1 font-playfair-display text-slate-100 mb-4">Innovative Stop Loss Consultants</h1>
//               <p className="text-2xl text-slate-200 mb-8">focused on managing healthcare risks with Integrity and Creativity.</p>
//               <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
//                 <div>
//                   <button className="btn text-white bg-blue-800 hover:bg-blue-700 w-full group" href="/request-demo">
//                     Get A Quote <span className="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
//                   </button>
//                 </div>
//                 <div>
//                   <button className="btn text-white bg-slate-700 hover:bg-slate-800 w-full" href="#0">Call Us</button>
//                 </div>
//               </div>
//             </div>

//             {/* Hero image */}
//             <ModalVideo01
//               thumb={'VideoThumb'}
//               thumbWidth={540}
//               thumbHeight={405}
//               thumbAlt="Modal video thumbnail"
//               video="https://res.cloudinary.com/codeshock/video/upload/v1702410985/Untitled_design_j56ry7.mp4"
//               videoWidth={1920}
//               videoHeight={1080} />            

//           </div>

//         </div>
//       </div>
//     </section>
//   )
// }





export default function HeroHome() {


  return (
    <div className="bg-slate-900">
     
      <div className="relative isolate overflow-hidden ">
        <img
          src={BRMHome}
          alt=""
          className="absolute inset-0 -z-10 h-full opacity-10 w-full object-cover"
          
        />
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" data-aos='fade-in'
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-4 py-2 text-4xl leading-6 text-blue-300 ring-1 ring-white/10 hover:ring-white/20" data-aos="fade-in" data-aos-delay='200'>
             BRM Specialty Markets
         
            </div>
          </div>
          <div className="text-center" data-aos="fade-in" >
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Innovative Stop Loss Consultants
            </h1>
            <p className="mt-6 mx-1 text-3xl leading-8 text-gray-200">
            focused on managing healthcare risks with Integrity and Creativity
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/contact"
                className=" bg-blue-500 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
              >
                Get A Quote
              </a>
              <a href="tel:8007828656" className="text-md font-semibold leading-6 text-white">
                Call Us <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
