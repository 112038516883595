import React from "react"
import HeroClaims from "../../sections/hero-claims"
import SpecImg from '../../images/images/claim-kit.jpg'



import { CheckIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'BRM CLAIMS KIT',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: '',
    description: '',
    features: [],
  },
  {
    name: 'TPA APPROVAL QUESTIONNAIRE',
    id: 'tier-team',
    href: '#',
    priceMonthly: '',
    description: '',
    features: [
    //   'Unlimited products',
    //   'Unlimited subscribers',
    //   'Advanced analytics',
    //   '1-hour, dedicated support response time',
    //   'Marketing automations',
    ],
  },
]

export default function ClaimsPage() {
  return (
    <>
    <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative  pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div  data-aos="fade-right" className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-20 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Claims
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
         
At BRM Specialty Markets, we provide our partners with excellent customer service and expertise. Our claims section includes guides and forms to help our partners understand what is needed to make the claims reimbursement process debonair and efficient.
                </p>
  
              </div>
            </div>
          </div>
        </div>
        <div  data-aos="fade-left" className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={SpecImg}
            alt=""
          />
        </div>
      </div>
      <section className="bg-slate-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

      </div>
    </section>
    <HeroClaims />
    
    </>
  )
}
