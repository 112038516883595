import React from "react"
import HeroMonthly from "../../sections/hero-monthly";

const MonthlyPage = (props) => {
  return (
    <div>
<HeroMonthly />
    </div>
  )
};

export default MonthlyPage;
